<template>
  <auth>
    <div v-if="is_loaded" />
    <loading v-else />
  </auth>
</template>

<script>
    import Auth from "@/components/Auth.vue";

    export default {
        components: {
            Auth
        },
        data() {
            return {
                code: {
                    code: this.$route.params.code,
                    id: this.$route.params.id,
                },
                is_valid: false,
                is_loaded: false,
            }
        },

        mounted() {
            this.fetch()
        },

        methods: {
            fetch() {
                this.is_valid = true;
                this.is_loaded = true;
                this.verifyDirectorSms();
            },

            verifyDirectorSms() {
                this.$axios.post('/api/v1/verify-director-sms', this.code).then(() => {
                    this.$toastr.s('You have successfully authorized the onboarding process of company', '');
                    setTimeout(function () {
                        window.location.href = '/dashboard/companies';
                    }, 1000);
                }).catch(() => {
                    this.$toastr.e('Code not valid!', '');
                    window.location.href = '/';
                })
            }
        }
    }
</script>

